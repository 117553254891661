interface CheckBoxBasicProps {
  id: string;
  checked: boolean;
  className?: string;
  handleChange?: (checked: boolean) => void;
  label?: string;
}

export function CheckBoxBasic({
  id,
  label,
  checked,
  handleChange,
  className,
}: CheckBoxBasicProps) {
  return (
    <div className={className}>
      <label className="text-black dark:text-white">
        <input
          id={id}
          type="checkbox"
          checked={checked}
          onChange={(e) => {
            handleChange && handleChange(e.currentTarget.checked);
          }}
        />
        <span
          className={`checkbox ml-2 ${checked ? "checkbox--active" : ""}`}
          aria-hidden="true"
        />
        {label}
      </label>
    </div>
  );
}
