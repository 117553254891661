import { Route, Routes } from "react-router-dom";
import { lazyImport } from "utils/lazyImport";

const { Login } = lazyImport(() => import("./Login"), "Login");

const { PassReset } = lazyImport(() => import("./PassReset"), "PassReset");

export const Auth = () => {
  return (
    <Routes>
      <Route index element={<Login />} />
      <Route path="reset-password" element={<PassReset />} />
      <Route path="*" element={<Login />} />
    </Routes>
  );
};
