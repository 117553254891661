import { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

import { NotificationType } from "stores/notifications";
import clsx from "clsx";

type PropTypes = {
  data: NotificationType;
  handleRemove: (id: number) => void;
};

const variants = {
  success: "bg-mainGreen text-white dark:bg-darkGreen",
  info: "bg-mainColor text-white",
  warning: "bg-yellow-500 text-white",
  error: "bg-mainRed text-white",
};

const Wrapper = styled.div<{ show: boolean }>`
  transition: all 0.4s;
  overflow: hidden;
  height: 30px;
  max-height: ${(props) => (props.show ? "30px" : "0")};
`;

export const Notification = ({ data, handleRemove }: PropTypes) => {
  const [show, setShow] = useState(false);
  const counter = useRef<number>(0);

  useEffect(() => {
    let interval = setInterval(() => {
      if (counter?.current === 3) {
        setShow(false);
        setTimeout(() => handleRemove(data.id), 400);
        return;
      }
      counter.current++;
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [counter, data.id, handleRemove]);

  useEffect(() => {
    setShow(true);
  }, []);

  const removeNotification = () => {
    setShow(false);
    setTimeout(() => handleRemove(data.id), 400);
  };

  return (
    <Wrapper
      show={show}
      className={clsx(
        "flex items-center content-center justify-center relative w-full text-[12px] font-bold text-center border-b border-white dark:border-none",
        variants[data.type]
      )}
    >
      {data.message}
      <FontAwesomeIcon
        onClick={() => removeNotification()}
        icon={["fas", "times"]}
        className="text-white text-xl absolute right-4 hover:cursor-pointer"
      />
    </Wrapper>
  );
};
