import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InputFieldMessage } from "../input/InputFieldMessage";
import clsx from "clsx";
import { themeStore } from "../../../stores/theme";

type Props = {
  isMulti?: boolean;
  value: any;
  onChange: (v: any, l: any) => void;
  options: any[];
  labelName?: string;
  error?: boolean;
  containerClass?: string;
};

export const OptionsOutsideSelect = ({
  isMulti,
  options,
  value,
  onChange,
  labelName,
  error,
  containerClass,
}: Props) => {
  const { showTheme } = themeStore();
  const customStyles = {
    control: (styles: any) => ({
      ...styles,
      //   border: errorText ? "1px solid red" : 0,
      backgroundColor: "#f7f7f7",
      padding: 6,
      outline: "none",
      boxShadow: "none",
      borderRadius: "0.5rem",
      border: 0,
      background: showTheme === "dark" && "#1b1b1b",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      color: showTheme === "dark" ? "white" : "black",
      paddingTop: 0,
      paddingBottom: 0,
      width: 32,
    }),
    groupHeading: () => ({
      fontSize: 16,
      paddingLeft: 5,
      fontWeight: "bold",
    }),
    singleValue: (styles: any) => ({
      ...styles,
      color: showTheme === "dark" && "white",
    }),
    menu: (styles: any) => ({
      ...styles,
      background: showTheme === "dark" && "#1b1b1b",
      color: showTheme === "dark" && "white",
    }),
    option: (styles: any, { isSelected }: any) => ({
      ...styles,
      backgroundColor: isSelected && "#2F7512",
      "&:hover": {
        color: showTheme === "dark" && "white",
        background: showTheme === "dark" && "#2F7512",
      },
    }),
  };

  const handleRemoveValue = (valueName: any) => {
    if (!onChange) return;

    const removedValue = value.find((val: any) => val.value === valueName);
    if (!removedValue) return;
    onChange(
      value.filter((val: any) => val.value !== valueName),
      { name: valueName, action: "remove-value", removedValue }
    );
  };

  return (
    <div className={clsx("pb-10", containerClass && containerClass)}>
      <label className="text-[18px] text-[#333333] block font-dmSans pb-1 dark:text-white">
        {labelName}
      </label>
      <Select
        styles={customStyles}
        options={options}
        value={value}
        onChange={onChange}
        isMulti={isMulti}
        controlShouldRenderValue={!isMulti}
        className={clsx(`${error && "border border-rose-500 rounded-lg"}`)}
      />
      {error && <InputFieldMessage text={"Please select a skills"} />}
      <div className="mt-1">
        {isMulti && value?.length
          ? value.map((val: any) => (
              <span
                key={val.value}
                className="bg-[#f7f7f7] text-mainColor border border-mainColor text-[14px] px-2 mr-1 font-dmSans dark:bg-darkGreen dark:text-white"
              >
                {val.label}
                <FontAwesomeIcon
                  name={val.value}
                  onClick={() => handleRemoveValue(val.value)}
                  icon={["fas", "xmark"]}
                  className="ml-2 hover:cursor-pointer"
                />
              </span>
            ))
          : null}
      </div>
    </div>
  );
};
