import create from "zustand";
import { persist, devtools } from "zustand/middleware";

type UserTypes = {
  id: number | string;
  email: string;
  username?: string;
  profile_photo?: string;
  first_name?: string;
  last_name?: string;
  new_available_vacation_days?: number;
  available_sick_leave_days?: number;
  role?: {
    id: string;
    name: string;
    permissions: {
      data: {
        id: string;
        name: string;
      }[];
    };
  };
};

type AuthOptions = {
  user: UserTypes;
  setUser: (user: UserTypes) => void;
  clearUser: () => void;
};

export const useAuth = create(
  devtools(
    persist<AuthOptions>(
      (set) => ({
        user: {} as UserTypes,
        setUser: (values) =>
          set(() => ({
            user: values,
          })),
        clearUser: () =>
          set(() => ({
            user: {} as UserTypes,
          })),
      }),
      {
        name: "qerp_",
        getStorage: () => localStorage,
      }
    )
  )
);
