import { axios } from "lib/axios";
import { useQuery } from "react-query";
import { ExtractFnReturnType, QueryConfig } from "lib/react-query";

type Options = {
  params: { role?: string };
  config?: QueryConfig<QueryFnType>;
};

export const getPermissions = (role: string): Promise<any> => {
  return axios.get(`/roles/permissions/${role}`);
};

type QueryFnType = typeof getPermissions;

export const useUserPermissions = ({ params, config }: Options) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ["userpermissions"],
    queryFn: () => params?.role && getPermissions(params.role),
    enabled: params?.role ? true : false,
  });
};
