import styled from "styled-components";
import clsx from "clsx";

const SpinnerContainer = styled.div`
  .loader {
    width: 20px;
    height: 20px;
    border: 3px solid #fff;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    margin-left: 10px;
    margin-top: 7px;
  }
`;

type TypeProps = {
  classNames?: string;
};
export const Spinner = ({ classNames }: TypeProps) => {
  return (
    <SpinnerContainer>
      <div className={clsx("loader", classNames && classNames)}></div>
    </SpinnerContainer>
  );
};
