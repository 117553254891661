import React from "react";
import clsx from "clsx";
import { UseFormRegisterReturn } from "react-hook-form";

type InputProps = {
  disabled?: boolean;
  labelName?: string;
  className?: string;
  registration?: Partial<UseFormRegisterReturn>;
  error?: boolean;
  value?: string | number;
  name?: string;
  defaultValue?: string;
  hidden?: boolean;
  handleChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  rows?: number;
};

export const TextArea = ({
  labelName,
  className,
  registration,
  error,
  value,
  name,
  hidden,
  defaultValue,
  handleChange,
  rows,
  disabled,
}: InputProps) => {
  return (
    <>
      {labelName && (
        <label className="text-[18px] text-[#333333] block font-dmSans pb-1 dark:text-white">
          {labelName}
        </label>
      )}
      <div className="flex flex-row relative">
        <textarea
          disabled={disabled && disabled}
          value={value && value}
          defaultValue={defaultValue && defaultValue}
          name={name && name}
          hidden={hidden && hidden}
          className={clsx(
            `rounded-lg leading-[34px] bg-[#f7f7f7] focus:outline-none w-full p-2 dark:bg-darkGray dark:text-white ${
              !error
                ? "mb-6 border border-transparent"
                : "border border-rose-500"
            }`,
            className
          )}
          {...registration}
          onChange={handleChange && handleChange}
          cols={10}
          rows={rows ? rows : 0}
        ></textarea>
      </div>
    </>
  );
};
