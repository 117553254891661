import create from "zustand";
import { persist, devtools } from "zustand/middleware";

type Props = {
  showTheme: string;
  changeTheme: (value: string) => void;
};

export const themeStore = create(
  devtools(
    persist<Props>(
      (set) => ({
        showTheme: "light",
        changeTheme: (value) =>
          set(() => ({
            showTheme: value,
          })),
      }),
      {
        name: "changetheme",
        getStorage: () => localStorage,
      }
    )
  )
);
