import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type ButtonType = {
  children?: React.ReactNode;
  type?: "button" | "submit" | "reset";
  variant?:
    | "primary"
    | "success"
    | "error"
    | "warning"
    | "errorOutline"
    | "successOutline";
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  deleteIcon?: boolean;
  xIcon?: boolean;
  editIcon?: boolean;
  commentIcon?: boolean;
  viewIcon?: boolean;
  isLoading?: boolean;
  planeIcon?: boolean;
  downloadIcon?: boolean;
  customIcon?: any;
};

const variants = {
  primary: "bg-mainColor border-transparent dark:bg-darkGreen",
  success: "bg-mainGreen border-transparent dark:bg-darkGreen",
  warning: "bg-mainYellow border-transparent",
  error: "bg-mainRed border-transparent",
  errorOutline:
    "bg-white text-mainRed border border-mainRed dark:bg-mainRed dark:border-none dark:text-white",
  successOutline: "text-mainColor border border-mainColor",
};

export const Button = ({
  children,
  type,
  variant,
  className,
  deleteIcon,
  xIcon,
  disabled,
  commentIcon,
  editIcon,
  viewIcon,
  isLoading,
  onClick,
  planeIcon,
  downloadIcon,
  customIcon,
}: ButtonType) => {
  return (
    <button
      disabled={disabled || isLoading ? true : false}
      onClick={onClick || undefined}
      type={type || "button"}
      className={clsx(
        ` rounded-md text-white text-[12px] px-4 py-1.5 mr-1`,
        variant ? `${variants[variant]}` : "bg-mainColor dark:bg-darkGreen",
        disabled || isLoading
          ? `opacity-40 cursor-not-allowed text-[#333333]`
          : "",
        deleteIcon ||
          xIcon ||
          editIcon ||
          viewIcon ||
          commentIcon ||
          planeIcon ||
          downloadIcon
          ? "py-1 px-2"
          : "",
        className && className
      )}
    >
      {deleteIcon ||
      xIcon ||
      editIcon ||
      viewIcon ||
      commentIcon ||
      planeIcon ||
      downloadIcon ||
      customIcon ? (
        <FontAwesomeIcon
          className=""
          icon={
            customIcon
              ? customIcon
              : deleteIcon
              ? ["fas", "trash"]
              : xIcon
              ? ["fas", "xmark"]
              : editIcon
              ? ["fas", "pen"]
              : viewIcon
              ? ["fas", "eye"]
              : commentIcon
              ? ["fas", "comment-dots"]
              : planeIcon
              ? ["fas", "plane-slash"]
              : downloadIcon
              ? ["fas", "download"]
              : ["fas", "trash"]
          }
        />
      ) : (
        children
      )}
    </button>
  );
};
