import clsx from "clsx";
import { Spinner } from "../../spinner/Spinner";

type ButtonType = {
  text: string;
  type?: "button" | "submit" | "reset";
  isLoading?: boolean;
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
};

export const FormButton = ({
  text,
  type,
  className,
  isLoading = false,
  disabled = false,
  onClick,
}: ButtonType) => {
  return (
    <button
      className={clsx(
        "bg-mainColor dark:bg-darkGreen rounded-md text-white text-[18px] w-3/5 h-12 hover:opacity-90 flex items-center justify-center",
        isLoading && "opacity-80",
        className && className
      )}
      onClick={onClick}
      type={type}
      disabled={isLoading || disabled}
    >
      {text}
      {isLoading && <Spinner />}
    </button>
  );
};
