import { useLocation } from "react-router-dom";
import Select, { components, Theme } from "react-select";
import { useTable } from "hooks";
import { themeStore } from "../../../stores/theme";

type ValueOptions = {
  id: number | string;
  name: string;
}[];

type DataOptions = {
  id: number;
  name: string;
};

type DataItemOptions = {
  id: number | string;
  name: string;
  full_name?: string;
  adress?: string;
  city?: {
    id: number;
    name: string;
    country: {
      id: number;
      name: string;
      code: string;
      phone_code: string;
    };
    state: {
      id: number;
      name: string;
    };
  };
  resource_type?: string;
};
// const indicatorSeparatorStyle = {
//   alignSelf: "stretch",
//   backgroundColor: "#ccc",
//   marginBottom: 8,
//   marginTop: 8,
//   width: 1,
// };

type FilterSelectProps = {
  data: DataOptions[];
  placeholder: string;
  handleSelected?: (value: any, type: any) => void;
  filterID?: string;
  value?: ValueOptions;
  filterWidth?: number;
  filterOptionsWitdh?: number;
  isMulti?: boolean;
  className?: string;
  styleSelect?: any;
};

export const FilterSelect = ({
  data,
  placeholder,
  filterID,
  value,
  filterWidth,
  filterOptionsWitdh,
  handleSelected,
  isMulti = true,
  className,
  styleSelect,
}: FilterSelectProps) => {
  const location = useLocation();
  const { handlePageChange } = useTable();
  const { showTheme } = themeStore();

  const indicatorSeparatorStyle = {
    alignSelf: "stretch",
    backgroundColor: "#ccc",
    marginBottom: 8,
    marginTop: 8,
    width: 1,
    display: location.pathname === "/reports/all-resources" ? "none" : "flex",
  };
  const customStyles = {
    control: (styles: any) => ({
      ...styles,
      //   border: errorText ? "1px solid red" : 0,
      backgroundColor: "#f7f7f7",
      padding: 6,
      outline: "none",
      boxShadow: "none",
      borderRadius: "0.5rem",
      border: 0,
      background: showTheme === "dark" && "#1b1b1b",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      color: showTheme === "dark" ? "white" : "black",
      paddingTop: 0,
      paddingBottom: 0,
      width: 32,
    }),
    groupHeading: () => ({
      fontSize: 16,
      paddingLeft: 5,
      fontWeight: "bold",
    }),
    singleValue: (styles: any) => ({
      ...styles,
      color: showTheme === "dark" && "white",
    }),
    menu: (styles: any) => ({
      ...styles,
      background: showTheme === "dark" && "#1b1b1b",
      color: showTheme === "dark" && "white",
    }),
    // menuList: (styles: any) => ({
    //   ...styles,
    //   maxHeight: false
    // }),
    option: (styles: any, { isSelected, isDisabled }: any) => ({
      ...styles,
      backgroundColor: isSelected
        ? showTheme === "dark"
          ? "#2F7512"
          : "#97c940"
        : "",
      "&:hover": {
        color: showTheme === "dark" ? "white" : "black",
        opacity: isDisabled ? 0.3 : 0.8,
        cursor: isDisabled ? "default" : "pointer",
      },
      "&:active": {
        background: "none",
      },
      textAlign: isDisabled && "center",
    }),
  };

  const handleChange = (selected: any) => {
    if (handleSelected) {
      handlePageChange(1);
      handleSelected(selected, filterID);
    }
  };

  const IndicatorSeparator = ({ props }: any) => {
    return <span style={indicatorSeparatorStyle} {...props} />;
  };
  const Option = (props: any) => {
    return (
      <div className="filter-select-option">
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label className="text-[12px]">{props.label}</label>
        </components.Option>
      </div>
    );
  };

  return (
    <Select
      styles={styleSelect ? styleSelect : customStyles}
      theme={(theme: Theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: "#97c940",
        },
      })}
      placeholder={placeholder}
      options={data?.map((items: DataItemOptions) => ({
        value: items.id,
        label: filterID === "client_id" ? items.full_name : items.name,
        id: items.id,
        filter_id: filterID,
      }))}
      value={
        value &&
        value.map((item: DataItemOptions) => ({
          value: item.id,
          label: filterID === "client_id" ? item.full_name : item.name,
          id: item.id,
          filter_id: filterID,
        }))
      }
      isMulti={isMulti}
      isSearchable={true}
      isClearable={false}
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      onChange={handleChange}
      components={{
        Option,
        IndicatorSeparator,
      }}
      // menuIsOpen
      controlShouldRenderValue={false}
      className={`w-full filter-select-dropdown ${className || ""} `}
    />
  );
};
