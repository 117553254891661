import create from "zustand";
import { persist, devtools } from "zustand/middleware";

type Props = {
  permissions: string[] | [];
  setPermissions: (value: string[]) => void;
};

export const permissionsStore = create(
  devtools(
    persist<Props>(
      (set) => ({
        permissions: [],
        setPermissions: (value) =>
          set(() => ({
            permissions: value,
          })),
      }),
      {
        name: "myp",
        getStorage: () => localStorage,
      }
    )
  )
);
