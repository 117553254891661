import create from "zustand";
import { persist, devtools } from "zustand/middleware";

type Props = {
  globalFilters: {
    [key: string]: {
      [key: string]: any;
    };
  };
  setGlobalFilters: (values: {
    page: string;
    filterName: string;
    filters: any;
  }) => void;
};

export const filtersStore = create(
  devtools(
    persist<Props>(
      (set) => ({
        globalFilters: {},
        setGlobalFilters: (values) =>
          set((state) => ({
            globalFilters: {
              ...state.globalFilters,
              [values.page]: {
                ...state.globalFilters[values.page],
                [values.filterName]: values.filters,
              },
            },
          })),
      }),
      {
        name: "qerp-filters",
        getStorage: () => localStorage,
      }
    )
  )
);
