import { useEffect, useState } from "react";
import { useDebounce } from "react-use";

import { ReactSelectForm } from "components/elements";

type Props = {
  control?: any;
  name: string;
  label?: string;
  errorText?: boolean;
  options?: { id?: number; value: string | number; label: string }[];
  optionsFunction?: Function;
  additionalArgs: any[];
  optionValueType: string;
  optionLabelType?: any;
  handleOuterChangedValue?: (e: any) => void;
  initialEditValue?: string;
  noOptionsText?: string;
};

export const ReactSelectDynamic = ({
  control,
  name,
  errorText,
  label,
  optionsFunction,
  additionalArgs,
  optionValueType,
  handleOuterChangedValue,
  initialEditValue,
  noOptionsText,
}: Props) => {
  // states
  const [searchValue, setSearchValue] = useState<string>("");
  const [options, setOptions] = useState<any>([]);

  useEffect(() => {
    initialEditValue && setSearchValue(initialEditValue);
  }, [initialEditValue]);

  useDebounce(
    async () => {
      if (searchValue.length >= 2) {
        let res =
          optionsFunction &&
          (await optionsFunction(searchValue, ...additionalArgs));
        let localOptions = (res?.data as unknown as any[])?.map(
          (resultItem: any) => {
            return {
              id: resultItem.id,
              label:
                resultItem.first_name && resultItem.last_name
                  ? `${resultItem.first_name} ${resultItem.last_name}`
                  : resultItem.name,
              value: resultItem[optionValueType],
            };
          }
        );

        setOptions(localOptions);
        let result = await res;
        return result;
      }
    },
    500,
    [searchValue]
  );

  return (
    <ReactSelectForm
      label={label}
      name={name}
      errorText={errorText}
      options={options}
      control={control}
      /*  defaultValueProp={initialEditValue} */
      handleSearchedValue={(e: string) => setSearchValue(e)}
      /*  handleChangedValue={(e: any) => {
          setSearchValue(e.label);
        }} */
      noOptionsText={noOptionsText && noOptionsText}
    />
  );
};
