import Select, { components } from "react-select";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { themeStore } from "../../../stores/theme";
import { useTranslation } from "react-i18next";

type Props = {
  label?: string;
  errorText?: boolean;
  options: {
    id?: number;
    value: string | number | ValueType;
    label: string;
    disabled?: boolean;
  }[];
  classNames?: string;
  defaultValueProp?: any;
  placeHolder?: string;
  handleChangedValue?: (e: any) => void;
  handleSearchedValue?: (e: string) => void;
  noOptionsText?: string;
  registration?: any;
  type?: "category";
  defaultValue?: any;
  value: string | number;
  onChange: (value: any) => void;
  isClearable?: boolean;
  padding?: number;
  disabled?: boolean;
  customPropsStyle?: any;
};

type ValueType = {
  label: string;
  value: number;
}[];

export const ReactSelect = ({
  onChange,
  value,
  errorText,
  options,
  label,
  placeHolder,
  classNames,
  handleSearchedValue,
  noOptionsText,
  disabled = false,
  isClearable = false,
  padding = 6,
  customPropsStyle,
}: Props) => {
  const { t } = useTranslation();
  const { showTheme } = themeStore();
  const customStyles = {
    control: (styles: any) => ({
      ...styles,
      border: errorText ? "1px solid red" : 0,
      backgroundColor: "#f7f7f7",
      padding: 6,
      outline: "none",
      boxShadow: "none",
      borderRadius: "0.5rem",
      background: showTheme === "dark" && "#1b1b1b",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      color: showTheme === "dark" ? "white" : "black",
      paddingTop: 0,
      paddingBottom: 0,
      width: 32,
    }),
    groupHeading: () => ({
      fontSize: 16,
      paddingLeft: 5,
      fontWeight: "bold",
    }),
    ClearIndicator: (...props: any) => ({
      ...props,
      cursor: "pointer",
    }),
    valueContainer: (styles: any) => ({
      ...styles,
      cursor: "pointer",
    }),
    indicatorsContainer: (styles: any) => ({
      ...styles,
      cursor: "pointer",
    }),
    singleValue: (styles: any, { isSelected }: any) => ({
      ...styles,
      color: showTheme === "dark" && "white",
    }),
    menu: (styles: any) => ({
      ...styles,
      background: showTheme === "dark" && "#1b1b1b",
      color: showTheme === "dark" && "white",
    }),
    option: (styles: any, { isSelected, isDisabled }: any) => ({
      ...styles,
      backgroundColor: isSelected
        ? showTheme === "dark"
          ? "#2F7512"
          : "#97c940"
        : "",
      "&:hover": {
        color: showTheme === "dark" ? "white" : "black",
        opacity: isDisabled ? 0.3 : 0.8,
        cursor: isDisabled ? "default" : "pointer",
      },
      "&:active": {
        background: "none",
      },
      textAlign: isDisabled && "center",
    }),
  };

  const ClearIndicator = (props: any) => {
    const { getStyles } = props;
    return (
      <div
        style={getStyles("clearIndicator", props)}
        className="cursor-pointer"
      >
        <div style={{ padding: "0px 5px" }}>
          <FontAwesomeIcon
            onClick={() => {
              props.clearValue();
            }}
            icon={["fas", "times"]}
            className=""
          />
        </div>
      </div>
    );
  };

  const NoOptionsMessage = (props: any) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className="custom-css-class">
          {noOptionsText && noOptionsText}
        </span>
      </components.NoOptionsMessage>
    );
  };

  return (
    <div className={clsx(classNames && classNames)}>
      {label && (
        <label className="text-[18px] text-[#333333] block font-dmSans pb-1 mt-2  dark:text-white">
          {label}
        </label>
      )}

      <Select
        styles={customPropsStyle ? customPropsStyle : customStyles}
        components={{
          ClearIndicator: (props: any) => ClearIndicator({ ...props }),
          NoOptionsMessage,
        }}
        value={options.find((c: any) => {
          return c.value === value;
        })}
        onChange={(val) => onChange(val?.value)}
        isClearable={isClearable}
        onInputChange={(e: any) => {
          handleSearchedValue && handleSearchedValue(e);
        }}
        options={options}
        placeholder={placeHolder || t("select")}
        className={clsx("rounded-[0.5rem]")}
        isDisabled={disabled}
        isOptionDisabled={(option) => (option?.disabled ? true : false)}
      />
    </div>
  );
};
