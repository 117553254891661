import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faCheckSquare,
  faGear,
  faChevronDown,
  faChartColumn,
  faSearch,
  faArrowCircleLeft,
  faPlusCircle,
  faTimesCircle,
  faPen,
  faCalendar,
  faBuilding,
  faLayerGroup,
  faUpload,
  faXmark,
  faEye,
  faEyeSlash,
  faAngleLeft,
  faAngleRight,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faSortDown,
  faArrowRight,
  faSortUp,
  faSort,
  faFilter,
  faBars,
  faClipboard,
  faChevronLeft,
  faTrash,
  faChevronRight,
  faCalendarCheck,
  faMobilePhone,
  faFolderOpen,
  faLocationDot,
  faListCheck,
  faUserGroup,
  faSuitcase,
  faHouseMedical,
  faQuestionCircle,
  faHourglassEnd,
  faLineChart,
  faDownload,
  faArrowUpRightFromSquare,
  faRecordVinyl,
  faCommentDots,
  faCheckCircle,
  faAsterisk,
  faFileContract,
  faEnvelopeOpen,
  faPlaneSlash,
  faNetworkWired,
  faUserCheck,
  faDollarSign,
  faBagShopping,
  faInfoCircle,
  faCheck,
  faMagnifyingGlass,
  faPaperPlane,
  faFileInvoiceDollar,
} from "@fortawesome/free-solid-svg-icons";
import {
  faEnvelope,
  faBell,
  faUser,
  faChartBar,
  faHandshake,
  faListAlt,
  faCreditCard,
  faFileLines,
  faTimesCircle as farTimesCircle,
  faClock,
  faCircle,
  faFile,
  faAddressBook,
  faSun,
  faMoon,
} from "@fortawesome/free-regular-svg-icons";

library.add(
  faPaperPlane,
  faCheckSquare,
  faEnvelope,
  faBell,
  farTimesCircle,
  faClipboard,
  faChartBar,
  faChevronLeft,
  faTrash,
  faChevronRight,
  faUser,
  faHandshake,
  faListAlt,
  faCreditCard,
  faMobilePhone,
  faFolderOpen,
  faFileLines,
  faGear,
  faChevronDown,
  faChartColumn,
  faSearch,
  faArrowCircleLeft,
  faPlusCircle,
  faTimesCircle,
  faPen,
  faDollarSign,
  faArrowRight,
  faCalendar,
  faBuilding,
  faLocationDot,
  faLayerGroup,
  faUpload,
  faXmark,
  faEye,
  faEyeSlash,
  faAngleLeft,
  faAngleRight,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faSortDown,
  faSortUp,
  faSort,
  faFilter,
  faBars,
  faCalendarCheck,
  faListCheck,
  faUserGroup,
  faClock,
  faSuitcase,
  faHouseMedical,
  faRecordVinyl,
  faQuestionCircle,
  faHourglassEnd,
  faDownload,
  faArrowUpRightFromSquare,
  faLineChart,
  faCommentDots,
  faCheckCircle,
  faCircle,
  faFile,
  faFileContract,
  faAsterisk,
  faAddressBook,
  faEnvelopeOpen,
  faPlaneSlash,
  faNetworkWired,
  faUserCheck,
  faDollarSign,
  faBagShopping,
  faInfoCircle,
  faSun,
  faMoon,
  faFileLines,
  faCheck,
  faMagnifyingGlass,
  faFileInvoiceDollar
);

export default library;

// Icon usage example:
// <FontAwesomeIcon icon={["far", "bell"]} />
