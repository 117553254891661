import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from "react-router-dom";
import { filtersStore } from "stores/filters";
import { useTranslation } from "react-i18next";

type FilterTypeOptions = {
  id: string | number;
  name: string;
  full_name?: string;
};

type FilterTagProp = {
  filterType?: FilterTypeOptions[];
  handleSetFilter?: (value: object) => void;
  filterID?: string | null;
  header?: string;
  booleanType?: boolean;
};

export const FilterTag = ({
  filterType,
  handleSetFilter,
  filterID,
  header,
  booleanType = false,
}: FilterTagProp) => {
  const { setGlobalFilters } = filtersStore();
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <div className="filter-tag flex flex-wrap">
      {filterType &&
        filterType.map((item: FilterTypeOptions) => (
          <span
            key={item.id}
            className="bg-white border inline-block mt-2 dark:border-white dark:bg-darkGray dark:text-white border-black text-[14px] px-2 mr-1 font-dmSans font-bold rounded-[3px] opacity-70 shadow-lg"
          >
            {header && !booleanType ? `${header}: ` : ""}{" "}
            {filterID === "client_id" ? item.full_name : t(item.name)}
            <FontAwesomeIcon
              onClick={() => {
                handleSetFilter!(
                  filterType.filter((f: FilterTypeOptions) => f.id !== item.id)
                );
                filterID &&
                  setGlobalFilters({
                    page: location.pathname,
                    filters: filterType.filter(
                      (f: FilterTypeOptions) => f.id !== item.id
                    ),
                    filterName: filterID,
                  });
              }}
              icon={["fas", "xmark"]}
              className="ml-2 hover:cursor-pointer"
            />
          </span>
        ))}
    </div>
  );
};
