import create from "zustand";

export type Props = {
  showApprovalModal: string;
  setShowApprovalModal: (val: string) => void;
};

export const approvalModalStore = create<Props>((set) => ({
  showApprovalModal: "",
  setShowApprovalModal: (val) =>
    set(() => ({
      showApprovalModal: val,
    })),
}));
