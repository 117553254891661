import { axios } from "lib/axios";
import React from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
// import { QueryConfig } from "lib/react-query";
import { useAuth } from "stores/auth";
import storage from "utils/storage";
import { UserOptions } from "../types";

type ResponseOptins = {
  data: UserOptions;
};

export const getUser = (): Promise<ResponseOptins> => {
  return axios.get("/user");
};

export const useUserData = () => {
  return useQuery({
    queryKey: ["userdata"],
    queryFn: getUser,
  });
};

export const useUpdateUser = () => {
  const { setUser, clearUser } = useAuth();
  const navigate = useNavigate();
  const updateUser = React.useCallback(
    async (path?: string) => {
      try {
        const data = await getUser();
        if (data?.data) {
          setUser(data?.data);
          if (path) navigate(path);
        }
      } catch (err: any) {
        if (err?.response?.status === 403) {
          clearUser();
          storage.clearToken();
        }
      }
    },
    [clearUser, navigate, setUser]
  );
  return { updateUser };
};
