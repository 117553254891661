import { forwardRef } from "react";

interface TextAreaProps extends React.ComponentPropsWithoutRef<"textarea"> {
  id: string;
  className?: string;
  label?: string;
  placeholder?: string;
  onEnter?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
}

export const TextAreaBasic = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    { id, className = "", label = "", placeholder = "", onEnter, ...props },
    ref
  ) => {
    return (
      <div className="flex flex-col">
        <label
          className="text-[12px] uppercase font-Fira font-medium mt-[15px] mb-[-9px] ml-[12px] z-[2] w-fit px-2 bg-white"
          htmlFor={id}
        >
          {label}
        </label>
        <textarea
          {...props}
          placeholder={placeholder}
          className={`p-2 rounded-lg border resize-none text-[14px] placeholder:text-[#75786C] placeholder:text-[14px] dark:bg-darkGray dark:text-white dark:border-none ${
            className ?? ""
          }`}
          onKeyDown={onEnter}
          ref={ref}
          id={id}
        />
      </div>
    );
  }
);

TextAreaBasic.displayName = "TextAreaBasic";
