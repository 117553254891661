import { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { lazyImport } from "utils/lazyImport";
import { Auth } from "features/authentication";
import { useAuth } from "stores/auth";
import { useQueryParams } from "utils/queryParams";
import storage from "utils/storage";
import { useUpdateUser } from "features/authentication/api/getUser";
import { useUserPermissions } from "features/authorization/api";
import { permissionsStore } from "stores/userPermissions";
import { approvalModalStore } from "stores/approvalModal";
import { themeStore } from "../stores/theme";

const { Protected } = lazyImport(() => import("./Protected"), "Protected");

export const AppRoutes = () => {
  const { setShowApprovalModal } = approvalModalStore();
  const queryParams = useQueryParams();
  const { user } = useAuth();
  const { updateUser } = useUpdateUser();
  const { permissions, setPermissions } = permissionsStore();
  const { showTheme } = themeStore();

  const { data } = useUserPermissions({
    params: {
      role: user?.role?.id,
    },
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (queryParams.get("vacation_id")) {
      setShowApprovalModal(queryParams.get("vacation_id") as string);
    }
    if (queryParams.get("days_off_id")) {
      setShowApprovalModal(queryParams.get("days_off_id") as string);
    }
  }, [queryParams, setShowApprovalModal]);

  useEffect(() => {
    updateUser();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data?.data?.length)
      setPermissions(data.data?.map((item: { name: string }) => item.name));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    let token = queryParams.get("token");
    if (token) {
      storage.setToken(token, "");
      updateUser();
    }
  }, [queryParams, updateUser, navigate]);

  useEffect(() => {
    document.body.className = showTheme;
  }, [showTheme]);

  return (
    <>
      <Routes>
        {user.email && permissions?.length ? (
          <Route path="/*" element={<Protected />} />
        ) : (
          <Route path="/*" element={<Auth />} />
        )}
      </Routes>
    </>
  );
};
