import React, { useEffect } from "react";
import clsx from "clsx";
import styled from "styled-components";
import { themeStore } from "../../../stores/theme";

type CardProps = {
  children: React.ReactNode;
  classNames?: string;
  modal?: boolean;
  removeScroll?: boolean;
  removeShadow?: boolean;
  bottomRounded?: boolean;
};

const Wrapper = styled.div<{
  removeScroll: boolean;
  removeShadow: boolean;
  theme: string;
}>`
  box-shadow: ${(props) =>
    props?.removeShadow ? "0" : "0px 0px 20px #0000000f"};
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #0000000f;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${(props) =>
      props.theme === "light" ? "#b7cf8f" : "#2F7512"};
    border-radius: 20px;
    border: ${(props) => (props.theme === "light" ? "1px solid #b7cf8f" : "")};
  }
  overflow-x: ${(props) => (props?.removeScroll ? "visible" : "auto")};
`;

export const Card = ({
  classNames,
  children,
  modal,
  removeShadow = false,
  removeScroll = false,
  bottomRounded = true,
}: CardProps) => {
  const { showTheme } = themeStore();

  useEffect(() => {
    const slider = document.querySelector(".items") as HTMLElement | null;
    let isDown = false;
    let startX: number;
    let scrollLeft: number;

    const handleMouseDown = (e: MouseEvent) => {
      if (slider) {
        isDown = true;
        startX = e.pageX - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;
        slider.classList.add("active");
      }
    };

    const handleMouseLeave = () => {
      isDown = false;
      slider?.classList.remove("active");
    };

    const handleMouseUp = () => {
      isDown = false;
      slider?.classList.remove("active");
    };

    const handleMouseMove = (e: MouseEvent) => {
      if (!isDown || !slider) return;

      const selection = window.getSelection();
      if (selection && selection.toString().length > 0) {
        // If there's an active text selection, don't initiate dragging.
        return;
      }

      const x = e.pageX - slider.offsetLeft;
      const walk = x - startX;

      if (Math.abs(walk) > 10) {
        // Threshold for dragging
        e.preventDefault();
        slider.scrollLeft = scrollLeft - walk;
      }
    };

    if (slider) {
      slider.addEventListener("mousedown", handleMouseDown);
      slider.addEventListener("mouseleave", handleMouseLeave);
      slider.addEventListener("mouseup", handleMouseUp);
      slider.addEventListener("mousemove", handleMouseMove);
    }

    return () => {
      if (slider) {
        slider.removeEventListener("mousedown", handleMouseDown);
        slider.removeEventListener("mouseleave", handleMouseLeave);
        slider.removeEventListener("mouseup", handleMouseUp);
        slider.removeEventListener("mousemove", handleMouseMove);
      }
    };
  }, []);

  const roundedClass = bottomRounded ? "rounded-3xl" : "rounded-t-3xl";

  return (
    <Wrapper
      removeScroll={removeScroll}
      removeShadow={removeShadow}
      className={clsx(
        `${
          modal ? "sm:p-2 xl:p-7" : "p-7"
        } ${roundedClass} bg-white dark:bg-black`,
        "items card-wrapper",
        classNames
      )}
      theme={showTheme}
    >
      {children}
    </Wrapper>
  );
};
