import clsx from "clsx";
import Picker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";

type PropsDatepicker = {
  state?: Date | null;
  disabled?: boolean;
  id?: string | number;
  setState: any;
  text?: string;
  removeClearButton?: boolean;
  classNames?: string;
  containerClass?: string;
  dateFormat?: string;
  showMonthYearPicker?: boolean;
  showYearPicker?: boolean;
  smallLabel?: string;
};

export const DatePicker = ({
  state = new Date(),
  setState,
  removeClearButton = true,
  disabled,
  id,
  classNames,
  containerClass,
  text = "select_date",
  dateFormat = "dd.MM.yyy",
  showMonthYearPicker = false,
  showYearPicker = false,
  smallLabel,
}: PropsDatepicker) => {
  const { t } = useTranslation();
  return (
    <div className={clsx(containerClass && containerClass)}>
      {smallLabel && (
        <small className="italic text-gray-500 dark:text-white">
          {smallLabel}
        </small>
      )}
      <Picker
        placeholderText={t(text)}
        dateFormat={dateFormat || "dd.MM.yyy"}
        onChange={(date) => (id ? setState(date, id) : setState(date))}
        selected={state}
        peekNextMonth
        calendarStartDay={1}
        showMonthYearPicker={showMonthYearPicker}
        showYearPicker={showYearPicker}
        showYearDropdown
        dropdownMode="select"
        className={clsx(
          "px-1 dark:!bg-darkGray dark:!text-white",
          classNames && classNames
        )}
        wrapperClassName={"dark:!bg-darkGray"}
        isClearable={removeClearButton}
        {...(showMonthYearPicker && { showMonthYearPicker })}
        {...(disabled && { disabled })}
      />
    </div>
  );
};
